// import React, { useState } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { useNavigate, Link } from "react-router-dom";
// import axios from "axios";
// import Loading from "../../../Warnings/Loading/Loading";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import adminImage from "../../../../assets-webapp/partner.jpg";

// // Validation schema for Formik
// const validationSchema = Yup.object({
//   email: Yup.string().email("Invalid email address").required("Required"),
//   password: Yup.string().required("Required"),
// });

// const AdminLogin = () => {
//   const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   // Function to handle form submission
//   const handleSubmit = async (values, { setSubmitting }) => {
//     setError("");
//     setLoading(true);
//     try {
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       };
//       const { data } = await axios.post("/api/users/login", values, config);
//       localStorage.setItem("adminInfo", JSON.stringify(data));
//       navigate("/admin-main-page");
//     } catch (err) {
//       setError(err.response?.data?.message || "Something went wrong");
//     } finally {
//       setLoading(false);
//       setSubmitting(false);
//     }
//   };

//   return (
//     <div className="flex flex-col lg:flex-row min-h-screen font-poppins">
//       {/* Left Side Image */}
//       <div className="hidden lg:flex lg:w-1/2 items-center justify-center">
//         <img
//           src={adminImage}
//           alt="Admin Login"
//           className="w-full h-full object-cover"
//         />
//       </div>

//       {/* Right Side Login Form */}
//       <div className="flex flex-col items-center justify-center p-8 w-full lg:w-1/2 bg-white">
//         <div className="w-full max-w-md">
//           <h1 className="text-3xl font-extrabold mb-4 text-center text-gray-800">
//             Dear Admin, Welcome!
//           </h1>
//           <h2 className="text-lg font-medium mb-6 text-center text-gray-600">
//             Please sign in to your account
//           </h2>

//           {/* Error Message */}
//           {error && (
//             <div className="bg-red-200 text-red-600 p-3 mb-4 text-center rounded-lg">
//               {error}
//             </div>
//           )}

//           {/* Loading */}
//           {loading ? (
//             <Loading />
//           ) : (
//             <Formik
//               initialValues={{ email: "", password: "" }}
//               validationSchema={validationSchema}
//               onSubmit={handleSubmit}
//             >
//               {({ isSubmitting }) => (
//                 <Form className="space-y-4">
//                   {/* Email Field */}
//                   <div className="relative">
//                     <Field
//                       type="email"
//                       name="email"
//                       placeholder="Enter your email"
//                       className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400"
//                       aria-label="Email"
//                     />
//                     <ErrorMessage
//                       name="email"
//                       component="div"
//                       className="text-red-500 text-sm mt-1"
//                     />
//                   </div>

//                   {/* Password Field */}
//                   <div className="relative">
//                     <Field
//                       type={showPassword ? "text" : "password"}
//                       name="password"
//                       placeholder="Enter your password"
//                       className="w-full p-4 pr-16 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400"
//                       aria-label="Password"
//                     />
//                     <button
//                       type="button"
//                       className="absolute inset-y-0 right-4 flex items-center"
//                       onClick={() => setShowPassword(!showPassword)}
//                       aria-label={
//                         showPassword ? "Hide Password" : "Show Password"
//                       }
//                     >
//                       <FontAwesomeIcon
//                         icon={showPassword ? faEyeSlash : faEye}
//                         size="lg"
//                         className="text-gray-600 mt-5"
//                       />
//                     </button>
//                     <ErrorMessage
//                       name="password"
//                       component="div"
//                       className="text-red-500 text-sm mt-1"
//                     />
//                   </div>

//                   {/* Remember Me and Forgot Password */}
//                   <div className="flex justify-between items-center mb-6">
//                     <div className="flex items-center space-x-2">
//                       <Field
//                         type="checkbox"
//                         name="rememberMe"
//                         id="rememberMe"
//                         className="form-checkbox h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
//                       />
//                       <label
//                         htmlFor="rememberMe"
//                         className="block text-sm mt-5 text-gray-700"
//                       >
//                         Remember me
//                       </label>
//                     </div>

//                     <Link
//                       to="/admin/login"
//                       className="text-sm mt-5 font-medium text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out"
//                     >
//                       Forgot password?
//                     </Link>
//                   </div>

//                   {/* Sign In Button */}
//                   <button
//                     type="submit"
//                     disabled={isSubmitting}
//                     className="w-full bg-blue-500 text-white p-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 shadow-md"
//                   >
//                     Sign In
//                   </button>
//                 </Form>
//               )}
//             </Formik>
//           )}

//           {/* Divider */}
//           <div className="flex items-center my-6">
//             <hr className="w-full border-gray-300" />
//             <span className="px-3 text-gray-500">OR</span>
//             <hr className="w-full border-gray-300" />
//           </div>

//           {/* Sign Up */}
//           <p className="text-center text-gray-500">
//             Don’t have an account?{" "}
//             <Link
//               to="/admin-create-account"
//               className="text-blue-500 hover:underline font-semibold"
//             >
//               Sign up
//             </Link>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminLogin;
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../../../Warnings/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import adminImage from "../../../../assets-webapp/partner.jpg";

// Validation schema for Formik
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

const AdminLogin = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (values, { setSubmitting }) => {
    setError("");
    setLoading(true);
  
    try {
      // API configuration
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      // Make API call to admin login endpoint
      const { data } = await axios.post(
        "/api/admin/login", // Ensure this matches the backend route
        values,
        config
      );
  
      console.log("Login response:", data);
  
      // Store the token and admin details in localStorage
      localStorage.setItem("adminToken", data.token);
      localStorage.setItem("adminInfo", JSON.stringify({
        id: data.id,
        name: data.name,
        email: data.email,
        isAdmin: data.isAdmin,
        pic: data.pic
      }));
  
      // Redirect to admin dashboard
      navigate("/admin-main-page");
    } catch (err) {
      console.error("Login error:", err.response || err.message);
  
      // Show error message returned from the backend or fallback message
      setError(err.response?.data?.message || "Login failed. Please try again.");
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };
  
  return (
    <div className="flex flex-col lg:flex-row min-h-screen font-poppins">
      <div className="hidden lg:flex lg:w-1/2 items-center justify-center">
        <img src={adminImage} alt="Admin Login" className="w-full h-full object-cover" />
      </div>

      <div className="flex flex-col items-center justify-center p-8 w-full lg:w-1/2 bg-white">
        <div className="w-full max-w-md">
          <h1 className="text-3xl font-extrabold mb-4 text-center text-gray-800">Admin Login</h1>
          <h2 className="text-lg font-medium mb-6 text-center text-gray-600">Sign in to access the dashboard</h2>

          {error && (
            <div className="bg-red-200 text-red-600 p-3 mb-4 text-center rounded-lg">
              {error}
            </div>
          )}

          {loading ? (
            <Loading />
          ) : (
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-4">
                  {/* Email Field */}
                  <div className="relative">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Password Field */}
                  <div className="relative">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      className="w-full p-4 pr-16 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-4 flex items-center"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} size="lg" className="text-gray-600" />
                    </button>
                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-blue-500 text-white p-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 shadow-md"
                  >
                    Sign In
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

